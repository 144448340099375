import NARCAN_LOGO from '../assets/images/NARCAN-Logo_White.png';
import NARCAN_LOGO_BLACK from '../assets/images/NARCAN-Logo_Black.png';
import NARCAN_PRODUCT from '../assets/images/narcan_product.png';
import NARCAN_LANDING from '../assets/images/narcan_landing.png';
import FFF_LOGO_FOOTER_BOAT from '../assets/images/footer-logo-fff.png';

export const IMAGES_URL = {
    NARCAN_LOGO: NARCAN_LOGO,
    NARCAN_LOGO_BLACK: NARCAN_LOGO_BLACK,
    NARCAN_PRODUCT: NARCAN_PRODUCT,
    NARCAN_LANDING: NARCAN_LANDING,
    FFF_LOGO_FOOTER_BOAT: FFF_LOGO_FOOTER_BOAT,
};