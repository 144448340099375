import { Redirect, Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { connect } from "react-redux";

import { themeNames } from "../hooks/styles/muiTheme";
import { getMuiTheme } from "../hooks/styles";
import { CssBaseline, StyledEngineProvider, ThemeProvider } from "@mui/material";
import { default as GlobalSnackbar } from '../components/snackbar';
import Order from "../pages/order";
import Payment from "../pages/payment";
import Product from "../pages/product";
import config from "../config";
import React from "react";

const Routes = (props: any) => {
  return (
    <ThemeProvider theme={getMuiTheme(props.selectedTheme || themeNames.LIGHT)}>
      <StyledEngineProvider injectFirst>
        <CssBaseline>
			  	<GlobalSnackbar />
          <Router>
            <Switch>
              <Route exact path="/" component={Product} />
              <Route exact path="/order" component={Order}/>
              <Route exact path="/payment" component={Payment} />
            </Switch>
          </Router>
        </CssBaseline>
      </StyledEngineProvider>
    </ThemeProvider>
  )
}

const mapStateToProps = (state: any) => ({
  dataIsPreloaded: state.ui.dataIsPreloaded,
  orderDetails: state.order.orderDetails || {},
})
const mapDispatchToProps = (dispatch: any) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Routes);

