import React from 'react';
import { connect } from 'react-redux';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import clsx from 'clsx';
import { CssBaseline, AppBar, Toolbar, Button, Box, Divider, Grid } from '@mui/material/';
import MenuListComposition from './menuList';
import { IMAGES_URL } from '../../../constants/Images';
import { BasePageStyles } from '../../../hooks/styles'
import Loading from '../../../components/loading';
import { useHistory } from "react-router-dom";
import { UIActions } from '../../../redux/actions';
import config from '../../../config';
import ReCAPTCHA from 'react-google-recaptcha';

export const recaptchaRef = React.createRef<ReCAPTCHA>()

const BasePage = (props: any) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 900px)'
  })
  const classes = BasePageStyles();
  const [urlIsProcessed, setUrlIsProcessed] = React.useState(false);
  const history = useHistory(); 

	const routeChange = (path: string) =>{ 
		history.push(path);
	}

  React.useEffect(() => {
    if (!urlIsProcessed) {
      props.setUrlFilter(window.location.search)
      setUrlIsProcessed(true)
    }
  }, [urlIsProcessed])

  React.useEffect(() => {
    if (!props.dataIsPreloaded) {
      props.preloadData()
    }
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className={clsx(classes.root, (props.isLoading ? "show-loader" : ""))}>
      <CssBaseline />

      <AppBar elevation={10} className={clsx(classes.appBar)}>
        <div className={classes.toolbarMargin} />
        <Toolbar className={classes.toolbar}>
          <img src={IMAGES_URL.NARCAN_LOGO_BLACK} alt="logo" className={classes.emergentLogo} style={{cursor: 'pointer'}} onClick={() => window.location.href = `${config.wordpressUrl}`}/>
          <MenuListComposition />
        </Toolbar>
      </AppBar>

      {urlIsProcessed && 
        <main className={classes.content}>
          {props.dataIsPreloaded ? props.children : <Loading message="Loading..." />}
        </main>
      }

      {props.isLoading ? <Loading message="Loading..." /> : ''}

      <AppBar elevation={0} className={clsx(isDesktopOrLaptop ? classes.appBarBottom : classes.appBarBottomMobile)}>
        <Toolbar className={isDesktopOrLaptop ? classes.footer : classes.footerMobile}>
          <Grid container columns={{xs: 12, md: 12}} sx={{minHeight: '20vh', justifyContent: 'center'}}>
            <Grid container item xs={12} md={7} sx={{display: 'flex', justifyContent: 'space-evenly', alignContent:'space-around'}}>
              <Grid item xs={12} md={6} sx={{display: 'flex', justifyContent: 'center', flexDirection: isDesktopOrLaptop ? 'row' : 'column', alignItems: isDesktopOrLaptop ? 'normal' : 'center'}}>
                <img src={IMAGES_URL.NARCAN_LOGO} alt="logo" className={isDesktopOrLaptop ? classes.narcanFooterLogo : classes.narcanFooterLogoMobile} />
              </Grid>
              <Grid item xs={12} md={6}>
                <p className={isDesktopOrLaptop ? classes.footerLegal : classes.footerLegalMobile}>NARCAN&reg; is a registered trademark of Emergent Operations Ireland Limited.</p>
              </Grid>
              <Grid item xs={12} md={6} sx={{display: 'flex', justifyContent: 'center', flexDirection: isDesktopOrLaptop ? 'row' : 'column', alignItems: isDesktopOrLaptop ? 'normal' : 'center'}}>
                  <img src={IMAGES_URL.FFF_LOGO_FOOTER_BOAT} alt="logo" className={isDesktopOrLaptop ? classes.footerLogo : classes.footerLogoMobile} />
              </Grid>
              <Grid item xs={12} md={6}>
                  <p className={isDesktopOrLaptop ? classes.footerLegal : classes.footerLegalMobile}>Distributed by FFF Enterprises, Inc.</p>
              </Grid>
              <p className={`${classes.footerCaptcha}`}>This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.</p>
            </Grid>
            <Grid item xs={0} md={1} sx={{display: 'flex', justifyContent: 'center'}}>
              <Divider orientation="vertical" flexItem variant={'middle'} sx={{ bgcolor: '#eee' }}/>
            </Grid>
            <Grid item xs={12} md={4} className={isDesktopOrLaptop ? classes.footerLinks : classes.footerLinksMobile}>
              <Button className={`${classes.footerLink}`} onClick={() => window.location.href = `${config.wordpressUrl}/contact`}>Contact Us</Button>
              <Button className={`${classes.footerLink}`} onClick={() => window.location.href = `${config.wordpressUrl}/privacy-statement`}>Privacy Statement</Button>
              <Button className={`${classes.footerLink}`} onClick={() => window.location.href = `${config.wordpressUrl}/terms-of-use`}>Terms of Use</Button>
              <Button className={`${classes.footerLink}`} onClick={() => window.location.href = `${config.wordpressUrl}/terms-of-sale`}>Terms and Conditions of Sale</Button>
              <Button id="ot-sdk-btn" className={`${classes.footerLink} ot-sdk-show-settings`}>Do Not Sell My Personal Information</Button>
            </Grid>
          </Grid>
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={config.captchaSiteKeyInvisible}
            badge='inline'
            onErrored={()=>console.log('onErrored called')}
          />
        </Toolbar>
      </AppBar>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  isLoading: state.ui.isLoading,
  selectedTheme: 'light', //state.user.selectedTheme
  dataIsPreloaded: state.ui.dataIsPreloaded
});

const mapDispatchToProps = (dispatch: any) => ({
  setUrlFilter: (queryString: string) => { },
  preloadData: () => dispatch(UIActions.preLoadData({})),
})

export default connect(mapStateToProps, mapDispatchToProps)(BasePage);

