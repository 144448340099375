import {useEffect,useRef} from 'react';
import { useDispatch } from 'react-redux';
import BasePage from '../common/base';
import { ProductContainer } from './product.container';
import { BasePageStyles } from '../../hooks/styles';
import {Constants} from "../../constants/Constants";
import { Container } from '@mui/material';
const Product = () => {
  const classes = BasePageStyles();
  const isFirstRender = useRef(true)
  const dispatch = useDispatch();
  useEffect(() => {
    isFirstRender.current = false
  }, [dispatch])

  return (
    <BasePage>
      <div className={classes.appBarSpacer}></div>
      <Container maxWidth={false} className={classes.container}>
        <ProductContainer />
      </Container>
    </BasePage>
  )
}

export default Product;