import { create, all } from 'mathjs'
const math = create(all,  {number: 'BigNumber', precision: 20});

export const FormatCurrency = (val: number) => {
	if (isNaN(val)) {
		console.error(`FormatCurrency expected a number but got: ${val}`);
		return ''
	}

	return math.format(Number(val),  {notation: 'fixed', precision: 2})
}