export interface Address {
	address: string;
	address2: string;
	city: string;
	state: string;
	zip: string;
	isValid?: boolean;
	errorMessage?: string;
}

export class AddressModel implements Address {
	address: string;
	address2: string;
	city: string;
	state: string;
	zip: string;

	isValid: boolean;
	errorMessage?: string = '';

	constructor() {
		this.address = '';
		this.address2 = '';
		this.city = '';
		this.state = '';
		this.zip = '';

		this.isValid = false;
	}
}
