import { combineReducers } from 'redux';

import { connectRouter } from 'connected-react-router';
import { History } from 'history';

import * as uiReducer from './ui.reducer';
import * as orderReducer from './order.reducer';
import * as captchaReducer from './captcha.reducer';
import orderShippingListReducer from '../../pages/payment/order-shipping-list.slice';

const createRootReducer = (history: History<any>) =>
	combineReducers(
		Object.assign(
			{ router: connectRouter(history), orderShippingList: orderShippingListReducer },
			uiReducer,
			orderReducer,
			captchaReducer,
		),
	);

export type RootState = ReturnType<ReturnType<typeof createRootReducer>>;

export default createRootReducer;
