import createReducer from './createReducer';
import { CaptchaActions } from '../actions'
import { CAPTCHA } from '../constants/actions';

export const captcha = createReducer({}, {
  [CAPTCHA.SET_CAPTCHA_TOKEN](state = {}, action: ReturnType<typeof CaptchaActions.setCaptchaToken>) {
    return { ...state, captchaToken: action.payload }
  },

  [CAPTCHA.SET_IS_CAPTCHA_VALID](state = {}, action: ReturnType<typeof CaptchaActions.setIsCaptchaValid>) {
    return { ...state, isCaptchaValid: action.payload }
  },
});