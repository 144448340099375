import { AddressModel, Address } from './address.model';
import { Product } from '../product/product.type';
import { CalculateTotal } from '../../hooks/functions/CalculateTotal';

export interface OrderShippingItemInterface {
	shippingAddress: Address; // Final destination of products
	productList: IorderItem[]; // List of items going to this address
	tax: number; // Tax for all items going to this address
	shipFromWarehouse: string; // The warehouse the product will be shipped from
	errorMessage?: string; // Any issues can be detailed here
}

export function toOrderShippingItemClass(rawObject: OrderShippingItemInterface): OrderShippingItem {
	const newItem = new OrderShippingItem(rawObject.productList[0].product);
	newItem.shippingAddress = rawObject.shippingAddress;
	newItem.productList = [];
	for (let i = 0; i < rawObject.productList.length; i++) {
		newItem.productList.push(new OrderItem(rawObject.productList[i].product, rawObject.productList[i].qty));
	}
	newItem.tax = rawObject.tax;
	newItem.shipFromWarehouse = rawObject.shipFromWarehouse;
	newItem.errorMessage = rawObject.errorMessage;
	return newItem;
}

export class OrderShippingItem implements OrderShippingItemInterface {
	shippingAddress: Address; // Final destination of products
	productList: OrderItem[]; // List of items going to this address
	tax: number; // Tax for all items going to this address
	shipFromWarehouse: string; // The warehouse the product will be shipped from
	errorMessage?: string; // Any issues can be detailed here

	constructor(product: Product, qty?: number) {
		this.shippingAddress = new AddressModel();
		this.productList = [new OrderItem(product, qty)];
		this.tax = 0;
		this.shipFromWarehouse = '';
	}

	getTaxAndValidationDto() {
		return {
			toCity: this.shippingAddress.city,
			toState: this.shippingAddress.state,
			toStreet: this.shippingAddress.address,
			toZip: this.shippingAddress.zip,
			amount: this.getTotalAmount(),
			lineItems: this.getAllLineItemsDto(),
		};
	}

	getTotalAmount() {
		let total = 0;
		for (let i = 0; i < this.productList.length; i++) {
			total += this.productList[i].amount();
		}
		return total;
	}

	//Total quantity going to this address
	getTotalQty() {
		let total = 0;
		for (let i = 0; i < this.productList.length; i++) {
			total += this.productList[i].qty;
		}
		return total;
	}

	getAllLineItemsDto() {
		const lineItems = [];
		for (let i = 0; i < this.productList.length; i++) {
			lineItems.push(this.productList[i].getLineItemsDto());
		}
		return lineItems;
	}
}

export interface IorderItem {
	product: Product; // The product being ordered
	qty: number; // Amount of this product to be ordered
}

export class OrderItem implements IorderItem {
	product: Product; // The product being ordered
	qty: number; // Amount of this product to be ordered

	constructor(product: Product, qty?: number) {
		this.product = product;
		this.qty = qty ? qty : 0;
	}

	// qty * product price in USD
	amount() {
		return CalculateTotal(this.qty, this.product.price);
	}

	getLineItemsDto() {
		return {
			quantity: this.qty,
			id: this.product.id,
		};
	}
}
