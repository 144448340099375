import * as ReactDOM from 'react-dom';
import { Provider, ReactReduxContext } from 'react-redux';
import configureStore, { history } from './redux/store/store';
import './assets/css/app.scss'
import Routes from "./routes/routes"; 
import { ConnectedRouter } from 'connected-react-router';
//if user previously selected dark theme, we save it in the DB and also localStorage
//this way, we avoid a "flash" by pre-setting Body dark-mode css before the user service has returned
const preloadDarkTheme = (localStorage.getItem('fdiPortal_themePreference') === 'dark');
const bgColor = '#fafafa';
const textColor = preloadDarkTheme ? '#fff' : '#000';
const css = `
    body {
      background-color: ${bgColor};
      color: ${textColor};
      height: 100%
    },
    html {
      height: 100%
    }
`
ReactDOM.render(
    <>
    <style>{css}</style>
      <Provider store={configureStore({})} context={ReactReduxContext}> 
      <ConnectedRouter history={history} />
        <Routes></Routes>
      </Provider>
  </>
   ,
  document.getElementById('root')
);