export default {
   getPaymentUrl: () => {
       if (window.location.origin.indexOf('localhost') > -1) {
           return "https://api.demo.convergepay.com/hosted-payments?ssl_txn_auth_token="
       }
       else {
           console.error('stil need to configure payment url for this env')
       }

       //*** todo: use real config, or else hardcode here to get demo or prod url based on current url in browser
   },

  PageLimitOptions: [10, 20, 30, 50, 100],

  DateFormats: [
    { "label": "YYYY-MM-DD", "value": "yyyy-MM-DD" },
    { "label": "YY-MM-DD", "value": "YY-MM-DD" },
    { "label": "MM-DD-YYYY", "value": "MM-DD-yyyy" },
    { "label": "MM-DD-YY", "value": "MM-DD-YY" },
    { "label": "DD-MM-YYYY", "value": "DD-MM-yyyy" },
    { "label": "DD-MM-YY", "value": "DD-MM-YY" },
  ],

  TimeFormats: [
    { "label": "24 Hour", "value": "HH:mm:ss zz" },
    { "label": "12 Hour", "value": "hh:mm:ssA zz" },
  ],

  StateCodes: [
    {label:"AL", value: "Alabama"},
    {label:"AK", value: "Alaska"},
    {label:"AZ", value: "Arizona"},
    {label:"AR", value: "Arkansas"},
    {label:"CA", value: "California"},
    {label:"CO", value: "Colorado"},
    {label:"CT", value: "Connecticut"},
    {label:"DE", value: "Delaware"},
    {label:"DC", value: "District Of Columbia"},
    {label:"FL", value: "Florida"},
    {label:"GA", value: "Georgia"},
    {label:"HI", value: "Hawaii"},
    {label:"ID", value: "Idaho"},
    {label:"IL", value: "Illinois"},
    {label:"IN", value: "Indiana"},
    {label:"IA", value: "Iowa"},
    {label:"KS", value: "Kansas"},
    {label:"KY", value: "Kentucky"},
    {label:"LA", value: "Louisiana"},
    {label:"ME", value: "Maine"},
    {label:"MD", value: "Maryland"},
    {label:"MA", value: "Massachusetts"},
    {label:"MI", value: "Michigan"},
    {label:"MN", value: "Minnesota"},
    {label:"MS", value: "Mississippi"},
    {label:"MO", value: "Missouri"},
    {label:"MT", value: "Montana"},
    {label:"NE", value: "Nebraska"},
    {label:"NV", value: "Nevada"},
    {label:"NH", value: "New Hampshire"},
    {label:"NJ", value: "New Jersey"},
    {label:"NM", value: "New Mexico"},
    {label:"NY", value: "New York"},
    {label:"NC", value: "North Carolina"},
    {label:"ND", value: "North Dakota"},
    {label:"OH", value: "Ohio"},
    {label:"OK", value: "Oklahoma"},
    {label:"OR", value: "Oregon"},
    {label:"PA", value: "Pennsylvania"},
    {label:"RI", value: "Rhode Island"},
    {label:"SC", value: "South Carolina"},
    {label:"SD", value: "South Dakota"},
    {label:"TN", value: "Tennessee"},
    {label:"TX", value: "Texas"},
    {label:"UT", value: "Utah"},
    {label:"VT", value: "Vermont"},
    {label:"VA", value: "Virginia"},
    {label:"WA", value: "Washington"},
    {label:"WV", value: "West Virginia"},
    {label:"WI", value: "Wisconsin"},
    {label:"WY", value: "Wyoming"}
  ],
}
