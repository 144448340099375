import { recaptchaRef } from "../../pages/common/base";

import { store } from '../../redux/store/store';

export const GetRecaptchaToken = async (isInvisible: boolean = true) => {
    try{
        if(isInvisible){
            const invisibleToken: any = await recaptchaRef?.current?.execute()
            return invisibleToken
        }
        const state = store.getState() as { captcha: { captchaToken: string } };
		const visibleToken = state.captcha.captchaToken;
        return visibleToken
    }
    catch(error){
        console.log('error', error)
    }
};
