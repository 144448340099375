import createReducer from './createReducer';
import { UIActions } from '../actions'
import { UI } from '../constants/actions';
import { isUndefined } from 'lodash';

export const ui = createReducer({}, {
  [UI.SHOW_LOADER](state = {}, action: ReturnType<typeof UIActions.showLoader>) {
    return { ...state, isLoading: action.payload }
  },

  [UI.SET_API_ERROR](state = {}, action: ReturnType<typeof UIActions.setApiError>) {
    return { ...state, errorMessage: action.payload }
  },

  [UI.PRELOAD_DATA_SUCCESS](state = {}, action: ReturnType<typeof UIActions.preLoadDataSuccess>) {
    return { ...state, dataIsPreloaded: true }
  },

  [UI.SET_SNACKBAR_OPEN](state = {}, action: ReturnType<typeof UIActions.setSnackbarOpen>) {
    return { ...state, isSnackbarOpen: action.payload };
  },
  [UI.SET_SNACKBAR_MESSAGE](state = {}, action: ReturnType<typeof UIActions.setSnackbarMessage>) {
    return {
      ...state,
      isSnackbarOpen: action.payload.message ? true : false,
      snackbarMessage: action.payload.message,
      snackbarStatus: action.payload.type || 'info',
      snackbarAutohide: isUndefined(action.payload.snackbarAutohide) ? 5000 : action.payload.snackbarAutohide,
    };
  },
});