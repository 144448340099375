import { takeLatest, throttle } from 'redux-saga/effects';
import { ORDER, UI } from '../constants/actions';
import * as ui from './ui.saga';
import * as order from './order.saga';

export default function* rootSaga() {
	yield takeLatest(UI.PRELOAD_DATA, ui.preLoadData);

	yield takeLatest(ORDER.CREATE_ORDER, order.createOrder);
	yield takeLatest(ORDER.GET_ORDER_DETAILS, order.getOrderDetails);
	yield throttle(1000, ORDER.GET_TAX_RATE, order.getTaxRate);
	yield takeLatest(ORDER.VALIDATE_ADDRESS, order.validateAddress);
	yield takeLatest(ORDER.VALIDATE_ADDRESS_LIST, order.validateAddressList);

	yield takeLatest(ORDER.GET_PRODUCT, order.getProduct);
	yield takeLatest(ORDER.GET_CC_PAYMENT_TOKEN, order.getCcPaymentToken);
}
