import { connect } from 'react-redux';
import { ProductComponent } from './product.component';
import { OrderActions } from '../../redux/actions';

const mapStateToProps = (state: any) => ({
  orderQuantity: state.order.orderQuantity || 12,
  products: state.order.products || [],
});

const mapDispatchToProps = (dispatch: any) => ({
  setOrderQuantity: (orderQuantity: number) => dispatch(OrderActions.setOrderQuantity(orderQuantity)),
});

export const ProductContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductComponent);