import moment from 'moment';
import { ORDER } from '../constants/api.url';
import Api from '../lib/api';
import { Order } from '../models/common/interfaces';

let testOrders = new Map<string, Order>([
	[
		'12345',
		{
			email: 'johndoe@gmail.com',
			orderDate: '12/01/2023',
			orderNumber: '12345',
			orderTotal: 49.99,
			name: 'John Doe',
			street: '44000 Winchester Rd',
			city: 'Temecula',
			state: 'CA',
			zip: '92590',
			trackingNumber: '1234567890',
		},
	],
	[
		'54321',
		{
			email: 'johndoe@gmail.com',
			orderDate: '12/01/2023',
			orderNumber: '12345',
			orderTotal: 49.99,
			name: 'John Doe',
			street: '44000 Winchester Rd',
			city: 'Temecula',
			state: 'CA',
			zip: '92590',
			trackingNumber: '',
		},
	],
]);

export class OrderService {
	private static instance: OrderService;
	private constructor() {}

	public static getInstance(): OrderService {
		if (!OrderService.instance) {
			OrderService.instance = new OrderService();
		}
		return OrderService.instance;
	}

	public createOrder = async (order: any): Promise<any> => {
		return await Api.post(`${ORDER.ORDERS}`, order);
	};

	public getOrderDetails = async (filter: any): Promise<any> => {
		const params = [{ key: 'filter', value: JSON.stringify(filter) }];
		return await Api.get(`${ORDER.ORDERS}`, params);
	};

	public getTaxRate = async (payload: any): Promise<any> => {
		return await Api.post(`${ORDER.GET_TAX}`, payload);
	};

	public validateAddress = async (payload: any): Promise<any> => {
		return await Api.post(`${ORDER.VALIDATE_ADDRESS}`, payload);
	};

	public validateAddressList = async (payload: any): Promise<any> => {
		return await Api.post(`${ORDER.VALIDATE_ADDRESS_LIST}`, payload);
	};

	public getProduct = async (payload: any): Promise<any> => {
		const params = [{ key: 'filter', value: JSON.stringify(payload) }];
		return await Api.get(`${ORDER.PRODUCT}`, params);
	};

	//for converge/elevon hosted payment!
	public getPaymentSessionToken = async (invoice: any): Promise<any> => {
		return await Api.post(`${ORDER.GET_CC_PAYMENT_TOKEN}`, {
			customerCode: invoice.ZzinvPayer,
			amount: invoice.InvoiceAmount,
			invoiceNumber: invoice.InvoiceNumber,
		});
	};
}
