import { action } from 'typesafe-actions';
import { UI } from '../constants/actions';

export const showLoader = (isLoading : boolean) => action(UI.SHOW_LOADER, isLoading);

export const setApiError = (payload: {errorMessage:string, severity:string, title:string} | null) => action(UI.SET_API_ERROR, payload);

export const preLoadData = (payload: any) => action(UI.PRELOAD_DATA, payload)
export const preLoadDataSuccess = () => action(UI.PRELOAD_DATA_SUCCESS)

export const setSnackbarOpen = (isOpen: boolean) => action(UI.SET_SNACKBAR_OPEN, isOpen);
export const setSnackbarMessage = (payload: object) => action(UI.SET_SNACKBAR_MESSAGE, payload);