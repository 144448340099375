import * as React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import config from '../../config';
import { connect } from 'react-redux';
import { CaptchaActions } from '../../redux/actions';

interface ReactRecaptchaProps {
    setCaptchaToken: Function
    setIsCaptchaValid: Function

}

const ReactRecaptcha = React.forwardRef(function CustomNumberInput(
  props: ReactRecaptchaProps,
) {

    const onCaptchaChange = (value: any) => {
		if (value) {
			props.setCaptchaToken(value);
			props.setIsCaptchaValid(true);
		}
	};

    return (
        <ReCAPTCHA 
            sitekey={config.captchaSiteKey} 
            onChange={onCaptchaChange} 
            onExpired={() => props.setIsCaptchaValid(false)}
        />
    );
});

const mapStateToProps = (state: any) => ({
	captchaToken: state.captcha.captchaToken || '',
	isCaptchaValid: state.captcha.isCaptchaValid || false
});

const mapDispatchToProps = (dispatch: any) => ({
	setIsCaptchaValid: (isValid: boolean) => dispatch(CaptchaActions.setIsCaptchaValid(isValid)),
	setCaptchaToken: (token: string) => dispatch(CaptchaActions.setCaptchaToken(token)),
});

const ReactRecaptchaConnected = connect(mapStateToProps, mapDispatchToProps)(ReactRecaptcha);
export default ReactRecaptchaConnected;
