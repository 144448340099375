import { connect } from 'react-redux';
import { OrderComponent } from './order.component';
import { OrderActions, UIActions } from '../../redux/actions';

const mapStateToProps = (state: any) => ({
  orderDetails: state.order.orderDetails || {},
  products: state.order.products || []
});

const mapDispatchToProps = (dispatch: any) => ({
  getOrderDetails: (orderNumber: string) => dispatch(OrderActions.getOrderDetails(orderNumber)),
  setSnackbarMessage: (payload: {message: string, type: string}) => dispatch(UIActions.setSnackbarMessage(payload)),
});

export const OrderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderComponent);