import React from 'react';
import { connect } from 'react-redux';
import { BasePageStyles } from '../../../hooks/styles'
import { Button, ClickAwayListener, Divider, Grow, MenuItem, MenuList, Paper, Popper, SwipeableDrawer, TextField } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useHistory } from 'react-router-dom';
import { OrderActions } from '../../../redux/actions';
import config from '../../../config';
import { useMediaQuery } from 'react-responsive';
import MenuIcon from '@mui/icons-material/Menu';

interface MenuListProps {}

const MenuListComposition = (props: MenuListProps) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 900px)'
  })
  const classes = BasePageStyles();
  const [openDrawer, setOpenOpenDrawer] = React.useState(false);
	const history = useHistory(); 
  const [onTrackingPage, setOnTrackingPage] = React.useState(false);

	const routeChange = (path: string) =>{ 
		history.push(path);
	}

  const renderContactButton = () => {
    return (
      <Button
        onClick={() => window.location.href = `${config.wordpressUrl}/contact`}
        className={'contactButton'}
        variant="contained"
        size='large'
      >Contact Us</Button>
    )
  }

  React.useEffect(() => {
		// Fake onHover on links when user is on the referenced page
    switch(window.location.pathname){
      case '/':
        setOnTrackingPage(false)
        break
      case '/order':
        setOnTrackingPage(true)
        break
      default:
        setOnTrackingPage(false)
        break
    }
	}, []);

  return (
    <div>
      {isDesktopOrLaptop ?
        <div>
          <Button
            onClick={() => window.location.href = `${config.wordpressUrl}/about`}
            className={classes.profileMenuBtn}
          >
            About Us
          </Button>
          <Button
            onClick={() => routeChange('/order')}
            className={onTrackingPage ? classes.fakeOnHoverNav : classes.profileMenuBtn}
          >
            Track Order
          </Button>
          {renderContactButton()}
        </div>
        :
        <div>
          <MenuIcon onClick={() => setOpenOpenDrawer(!openDrawer)} fontSize='large'/>
          <SwipeableDrawer
            anchor={'top'}
            open={openDrawer}
            onClose={() => setOpenOpenDrawer(false)}
            onOpen={() => setOpenOpenDrawer(true)}
          >
            <MenuList>
              <MenuItem>
                <Button
                  onClick={() => window.location.href = `${config.wordpressUrl}/about`}
                  className={classes.profileMenuBtn}
                >
                  About Us
                </Button>
                <Button
                  onClick={() => routeChange('/order')}
                  className={classes.profileMenuBtn}
                >
                  Track Order
                </Button>
              </MenuItem>
              <Divider />
              <MenuItem>{renderContactButton()}</MenuItem>
            </MenuList>
          </SwipeableDrawer>
        </div>
      }
    </div>
  );
}

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(MenuListComposition);


