import { makeStyles } from '@mui/styles';
import { globalColors } from './muiTheme';

const drawerWidth = 240;

export const BasePageStyles = makeStyles(
	theme => ({
		//top-level customizations for the entire app can be made here
		//this is useful for 3rd party non-MUI components we lack internal control over,
		// eg Highcharts
		root: {
			display: 'flex',
			flexDirection: 'column',
			minHeight: '100vh',
			maxWidth: '100vw',
			color: theme.palette.text.primary,
		},
		pageTitle: {
			color: theme.palette.text.secondary,
		},
		toolbar: {
			height: '8vh',
			display: 'flex',
			justifyContent: 'space-evenly',
		},
		profileMenuBtn: {
			backgroundColor: 'inherit !important',
			color: globalColors.BLACK,
			fontFamily: '"Jost", sans-serif',
			fontSize: '18px',
			fontWeight: 600,
			padding: '13px 20px',
			'&:hover': {
				color: globalColors.NARCAN_PINK,
				textDecoration: 'underline',
				textDecorationColor: '#ED1C24',
				textDecorationThickness: '3px',
				textUnderlineOffset: '13px',
			},
		},
		fakeOnHoverNav: {
			backgroundColor: 'inherit !important',
			fontFamily: '"Jost", sans-serif',
			fontSize: '18px',
			fontWeight: 600,
			padding: '13px 20px',
			color: globalColors.BLACK,
			textDecoration: 'underline',
			textDecorationColor: globalColors.NARCAN_BLUE,
			// textDecorationColor: '#ED1C24',
			textDecorationThickness: '3px',
			textUnderlineOffset: '13px',
			'&:hover': {
				// color: globalColors.NARCAN_BLUE,
				textDecoration: 'underline',
				textDecorationColor: globalColors.NARCAN_BLUE,
				textDecorationThickness: '3px',
				// textUnderlineOffset: '13px'
			},
		},
		profileName: {
			padding: '6px 0 6px 10px',
			color: theme.palette.secondary.main,
		},
		appBar: {
			minHeight: '8vh',
			display: 'flex',
			zIndex: theme.zIndex.drawer + 1,
			transition: theme.transitions.create(['width', 'margin'], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
		},
		appBarSpacer: theme.mixins.toolbar,
		appBarBottom: {
			position: 'static',
			// position: "fixed",
			top: 'auto',
			bottom: 0,
			display: 'flex',
			minHeight: '20vh !important',
		},
		appBarBottomMobile: {
			position: 'static',
			top: 'auto',
			bottom: 0,
			display: 'flex',
			minHeight: '20vh !important',
		},
		toolbarMargin: {
			backgroundColor: globalColors.BLACK,
			height: '2vh',
			width: '100%',
		},
		footer: {
			width: '100%',
			display: 'flex',
			justifyContent: 'space-between',
			backgroundColor: globalColors.BLACK,
			minHeight: '20vh !important',
		},
		footerMobile: {
			width: '100%',
			display: 'flex',
			justifyContent: 'space-between',
			flexDirection: 'column',
			paddingBottom: '10px',
			backgroundColor: globalColors.BLACK,
			minHeight: '20vh !important',
			paddingTop: '20px',
		},
		footerLinks: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'flex-start',
		},
		footerLinksMobile: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
		},
		footerCaptcha: {
			display: 'flex',
			color: globalColors.WHITE,
			textAlign: 'center',
			fontSize: '1em',
			'& a': {
				color: globalColors.NARCAN_PINK,
				paddingLeft: '5px',
				paddingRight: '5px',
				'&:hover': {
					color: globalColors.NARCAN_BRIGHT_PINK,
				},
			},
		},
		footerLogo: {
			// maxWidth: '6vw',
			maxWidth: '186px',
			height: '66px',
		},
		footerLogoMobile: {
			textAlign: 'center',
			// maxWidth: '25vw',
			maxWidth: '186px',
			height: '66px',
		},
		narcanFooterLogo: {
			// maxWidth: '12vw',
			maxWidth: '256px',
			height: '74px',
		},
		narcanFooterLogoMobile: {
			textAlign: 'center',
			// maxWidth: '50vw',
			maxWidth: '256px',
			height: '74px',
		},
		footerLegal: {
			color: 'white',
			fontFamily: "'Work Sans', sans-serif;",
			fontWeight: 300,
			fontSize: '14px',
			lineHeight: '24px',
			textAlign: 'left',
		},
		footerLegalMobile: {
			color: 'white',
			fontFamily: "'Work Sans', sans-serif;",
			fontWeight: 300,
			fontSize: '14px',
			lineHeight: '24px',
			textAlign: 'center',
		},
		content: {
			flexGrow: 1,
			height: '100%',
			// minHeight: '100vh',
			paddingBottom: 50,
		},
		container: {
			paddingTop: theme.spacing(10),
			paddingBottom: theme.spacing(4),
			display: 'flex',
			justifyContent: 'center',
		},
		pageContainer: {
			display: 'flex',
			maxWidth: '75%',
		},
		pageContainerGrid: {
			maxWidth: '100%',
			height: '100%',
		},
		topmenu: {
			width: '100%',
			display: 'inherit',
		},
		topmenuTitle: {
			cursor: 'pointer',
			margin: '0px',
		},
		errorAlert: {
			paddingLeft: '24px',
			paddingRight: '24px',
		},
		emergentLogo: {
			maxHeight: '52px',
			maxWidth: '180px',
		},
		trackingForm: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
		},
		trackingInput: {
			margin: '10px 5px 5px 5px',
		},
		orderDetailsPaper: {
			marginTop: '10px',
			'& p': {
				margin: '0px',
				textAlign: 'center',
			},
			'& h3': {
				margin: '10px',
				textAlign: 'center',
			},
			'& h4': {
				textAlign: 'center',
			},
		},
		logoDescription: {
			maxHeight: '100%',
		},
		paymentCard: {
			height: '100%',
			padding: '10px',
			display: 'grid',
			gap: '10px',
			'& h3': {
				margin: '0px',
			},
		},
		paymentCardForm: {
			width: '100%',
			marginTop: '10px',
		},
		paymentDetails: {
			display: 'flex',
			justifyContent: 'space-evenly',
			flexDirection: 'column',
			alignItems: 'flex-start',
			height: '100%',
			'& ul': {
				listStyle: 'none',
				display: 'table',
				paddingRight: '0px',
				paddingLeft: '0px',
			},
			'& li': {
				display: 'table-row',
			},
			'& .paymentDetailsLabel': {
				display: 'table-cell',
				paddingRight: '1em',
			},
			'& .paymentDetailsData': {
				display: 'table-cell',
			},
			'& h3': {
				margin: '0px',
			},
		},
		termsCheck: {
			marginRight: 0,
			fontSize: '1em',
			'& a': {
				color: globalColors.NARCAN_PINK,
				'&:hover': {
					color: globalColors.NARCAN_BRIGHT_PINK,
				},
			},
		},
		footerLink: {
			background: `rgba(0, 0, 0, 0) !important`,
			border: 'unset!important',
			color: `${globalColors.WHITE} !important`,
			textAlign: 'center',
			fontSize: '1em !important',
			padding: '0.8em 2em !important',
			'&:hover': {
				background: `rgba(0, 0, 0, 0)!important`,
				color: `${globalColors.NARCAN_BRIGHT_PINK} !important`,
			},
		},
		baseModal: {
			top: `${50}%`,
			left: `${50}%`,
			transform: `translate(-${50}%, -${50}%)`,
			maxWidth: '600px',
			maxHeight: '100%',
			overflow: 'scroll',
			position: 'absolute',
			width: 'auto',
			backgroundColor: theme.palette.background.paper,
			border: '2px solid #e0e0e0',
			borderRadius: '3px',
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
		},
		validateAddressModal: {
			color: theme.palette.text.primary,
		},
	}),
	{ index: 1 },
);
export default BasePageStyles;
